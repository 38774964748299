/**
 * Avalanche Nav & Mega Nav
 * - triggers nav to appear
 */
( function() {
  const megaNavToggles = document.querySelectorAll('.js-mega-nav-toggle');
  const megaNavSubToggles = document.querySelectorAll('.js-mega-nav-sub-toggle');
  const megaNavContainers = document.querySelectorAll('.js-mega-nav-container');
  const megaNavSubContainers = document.querySelectorAll('.js-mega-nav-sub');
  const megaNavClose = document.querySelectorAll('.mega-nav-close');
  const navToggle = document.getElementById('navtoggle');
  const navWrap = document.getElementById('nav');
  const headerTag = document.getElementById('masthead');
  const navMenuItems = document.querySelectorAll('#navmenu .menu-item');

  const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

  function init() {
    if (navWrap) {
      bindEvents();
    }
  }

  function bindEvents() {
    navToggle.addEventListener('click', toggleNavMenu);
    headerTag.addEventListener('keydown', handleNavKeyPress);

    for (var a = 0; a < megaNavClose.length; a++) {
      megaNavClose[a].addEventListener('click', closeMegaNavMenus);
    }

    for (var b = 0; b < navMenuItems.length; b++) {
      if(!navMenuItems[b].classList.contains('js-mega-nav-toggle')) {
        navMenuItems[b].addEventListener('click', function() {
          navWrap.classList.remove('open');
        });
      }
    }

    for (var c = 0; c < megaNavToggles.length; c++) {
      megaNavToggles[c].addEventListener('click', toggleMegaNav);
    }

    for (var d = 0; d < megaNavSubToggles.length; d++) {
      megaNavSubToggles[d].addEventListener('click', toggleMegaNavSubMenu);
    }

    for (var e = 0; e < megaNavContainers.length; e++) {
      closeMegaNavOnClickOutside( megaNavContainers[e]);
    }

    // Close mega navs if window resizes
    window.matchMedia('(max-width: 767px)').addListener(() => {
      closeMegaNavMenus();
    })

  }

  // Toggle Basic Nav Menu (via hamburger icon)
  function toggleNavMenu() {
    const isExpanded = navToggle.getAttribute('aria-expanded') === 'true' | false;
    navToggle.setAttribute('aria-expanded', !isExpanded);
    navWrap.classList.toggle('open');
    navWrap.classList.toggle('closed');
    headerTag.classList.toggle('open');
  }

  // Mega Nav Functions
  function toggleMegaNav(event) {
    event.preventDefault();
    const isExpanded = event.target.getAttribute('aria-expanded') === 'true' | false;
    const megaNavMenu = event.target.nextElementSibling;
    const isOpen = megaNavMenu.classList.contains('is-open');

    closeMegaNavMenus();
    if(isOpen) {
      megaNavMenu.classList.add('is-open');
    }
    event.target.setAttribute('aria-expanded', !isExpanded);
    megaNavMenu.classList.toggle('is-open');
    megaNavMenu.setAttribute('aria-hidden', !!isExpanded);
  }

  function toggleMegaNavSubMenu(event) {
    event.preventDefault();
    const isExpanded = event.target.getAttribute('aria-expanded') === 'true' | false;
    const megaNavSubMenu = event.target.nextElementSibling;
    const isOpen = megaNavSubMenu.classList.contains('is-open');

    closeMegaNavSubMenus()
    if(isOpen) {
      megaNavSubMenu.classList.add('is-open');
    }
    event.target.setAttribute('aria-expanded', !isExpanded);
    megaNavSubMenu.classList.toggle('is-open');
    megaNavSubMenu.setAttribute('aria-hidden', !!isExpanded);
  }

  function closeMegaNavMenus() {
    for (var f = 0; f < megaNavContainers.length; f++) {
      megaNavContainers[f].classList.remove('is-open');
      megaNavContainers[f].setAttribute('aria-hidden', true);
    }
    for (var g = 0; g < megaNavToggles.length; g++) {
      megaNavToggles[g].setAttribute('aria-expanded', false);
    }
    closeMegaNavSubMenus();
  }

  function closeMegaNavSubMenus() {
    for (var h = 0; h < megaNavSubContainers.length; h++) {
      megaNavSubContainers[h].classList.remove('is-open');
      megaNavSubContainers[h].setAttribute('aria-hidden', true);
    }
    for (var i = 0; i < megaNavSubToggles.length; i++) {
      megaNavSubToggles[i].setAttribute('aria-expanded', false);
    }
  }

  function closeMegaNavOnClickOutside(element) {
    const outsideClickListener = event => {
      if(!event.target.classList.contains('js-mega-nav-toggle')) {
        if (!element.contains(event.target) && isVisible(element)) {
          closeMegaNavMenus();
        }
      }
    }
    document.addEventListener('click', outsideClickListener)
  }

  function getMobileLastNavTab() {
    const mobileNavSelectors = [
      '.nav-menu-item',
      '.mega-nav-toggle[aria-expanded="false"]',
      '.mega-nav-toggle[aria-expanded="true"] + .is-open .mega-nav-sub-toggle',
      '.mega-nav-sub-toggle[aria-expanded="true"] + .is-open .mega-nav-item',
      '[tabindex]:not([tabindex="-1"])'
    ].toString();
    const mobileNavTabStopList = Array.from(navWrap.querySelectorAll(mobileNavSelectors));
    const mobileNavTabStops = mobileNavTabStopList.filter(item => {
      return window.getComputedStyle(item).display !== 'none';
    });
    return mobileNavTabStops[mobileNavTabStops.length - 1];
  }

  function getDesktopLastNavTab() {
    const desktopNavSelectors = [
      '.is-open .mega-nav-sub-toggle',
      '.is-open .mega-nav-item',
      '.is-open .nav-cta-link',
      '[tabindex]:not([tabindex="-1"])'
    ].toString();
    const desktopNavTabStopList = Array.from(navWrap.querySelectorAll(desktopNavSelectors));
    const desktopNavTabStops = desktopNavTabStopList.filter(item => {
      return window.getComputedStyle(item).display !== 'none';
    });
    return desktopNavTabStops[desktopNavTabStops.length - 1];
  }

  function handleNavKeyPress(e) {
    const currentMegaNavClose = document.querySelector('.is-open .mega-nav-close');
    const firstNavTabStop = window.matchMedia('(max-width: 767px)').matches ? navToggle : currentMegaNavClose;
    const lastNavTabStop = window.matchMedia('(max-width: 767px)').matches ? getMobileLastNavTab() : getDesktopLastNavTab();

    // Trap focus when nav is open
    if (e.keyCode === 9 && (navWrap.classList.contains('open') || currentMegaNavClose)) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstNavTabStop) {
          e.preventDefault();
          lastNavTabStop.focus();
        }
      // TAB
      } else {
        if (document.activeElement === lastNavTabStop) {
          e.preventDefault();
          firstNavTabStop.focus();
        }
      }
    }
    // Escape key to exit
    if (e.keyCode === 27 && navWrap.classList.contains('open')) {
      toggleNavMenu();
    }
    if (e.keyCode === 27 && currentMegaNavClose) {
      closeMegaNavMenus();
    }
  }

  return init();
} )();
